// extracted by mini-css-extract-plugin
export var aboutSubhead = "about-module--aboutSubhead--3vgfr";
export var aboutText = "about-module--aboutText--2byv2";
export var bulletSection = "about-module--bulletSection--PAC7N";
export var container = "about-module--container--peLpA";
export var container2 = "about-module--container2--VxJhm";
export var divider = "about-module--divider--NhGWn";
export var graf = "about-module--graf--LvhNq";
export var graf2 = "about-module--graf2--PVh91";
export var highlight = "about-module--highlight--ozSDH";
export var link = "about-module--link--0dnEe";
export var photo = "about-module--photo--smL33";
export var subGraf = "about-module--subGraf--A-zjV";